.main {
  flex-grow: 1;
  scroll-margin-top: var(--header-height);
}

.profile {
  margin-block-end: 24px;
}

.profile :where(.avatar-circle, .avatar-rounded) {
  width: 160px;
}

.profile .title-2 {
  margin-block: 16px 4px;
}

.username {
  line-height: 20px;
  margin-block-end: 12px;
}

.profile .bio {
  margin-block: 12px;
  max-width: 80ch;
}

.profile .btn-secondary {
  max-width: 100%;
}

.profile-meta {
  margin-block: 24px;
}

.profile-meta .meta-item,
.profile-stats .stats-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-block: 8px;
}

.profile-meta .meta-text {
  font-size: var(--label-1);
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: var(--transition-short);
}

.profile-meta a.meta-text:where(:hover, :focus-visible) {
  color: var(--primary);
}

.profile-stats .stats-item {
  color: var(--on-surface-variant);
}

.profile-stats .body {
  font-weight: var(--weight-medium);
  color: var(--weight-medium);
}

.profile .footer {
  display: none;
}

@media (min-width: 768px) {
  .main .container {
    display: grid;
    grid-template-columns: 240px 1fr;
    gap: 36px;
    align-items: flex-start;
  }

  .profile :where(.avatar-circle, .avatar-rounded) {
    width: 200px;
  }

  .profile {
    margin-block-end: 0;
    position: sticky;
    top: calc(var(--header-height) + 16px);
  }

  .profile .footer {
    display: block;
    margin-block-start: 24px;
  }
}

@media (min-width: 992px) {
  .main .container {
    grid-template-columns: 280px 1fr;
  }

  .profile :where(.avatar-circle, .avatar-rounded) {
    width: 280px;
  }
}
