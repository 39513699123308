@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@24,400,1,0");

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0);

  /* Dark Colors */

  --background-dark: hsl(220, 40%, 13%);
  --on-background-dark: hsl(0, 0%, 100%);
  --primary-dark: hsl(212, 100%, 50%);
  --primary-hover-dark: hsl(212, 100%, 45%);
  --primary-outline-dark: hsl(212, 100%, 46%);
  --primary-outline-hover-dark: hsl(212, 100%, 55%);
  --surface-dark: hsl(222, 41%, 17%);
  --on-surface-dark: hsl(223, 23%, 61%);
  --on-surface-2-dark: hsl(222, 16%, 72%);
  --surface-variant-dark: hsl(223, 40%, 20%);
  --surface-variant-hover-dark: hsl(223, 40%, 24%);
  --on-surface-variant-dark: hsl(223, 13%, 54%);
  --surface-variant-outline-dark: hsl(222, 41%, 23%);
  --surface-variant-outline-hover-dark: hsl(220, 40%, 40%);
  --error-dark: hsl(0, 100%, 71%);
  --error-alpha-10-dark: hsla(0, 100%, 71%, 0.1);
  --outline-dark: hsl(223, 40%, 20%);
  --surface-1-dark: hsl(220, 40%, 17%);
  --surface-1-hover-dark: hsl(219, 40%, 21%);
  --surface-1-outline-dark: hsl(219, 40%, 20%);
  --surface-1-outline-hover-dark: hsl(220, 40%, 40%);
  --alpha-80-dark: hsla(0, 0%, 100%, 0.8);
  --alpha-70-dark: hsla(0, 0%, 100%, 0.7);
  --alpha-60-dark: hsla(0, 0%, 100%, 0.6);
  --alpha-50-dark: hsla(0, 0%, 100%, 0.5);
  --alpha-10-dark: hsla(0, 0%, 100%, 0.1);
  --alpha-5-dark: hsla(0, 0%, 100%, 0.05);
  --overlay-bg-dark: hsla(220, 40%, 7%, 0.5);

  /* Light Colors */

  --background-light: hsl(213, 100%, 94%);
  --on-background-light: hsl(220, 40%, 13%);
  --primary-light: hsl(212, 100%, 50%);
  --primary-hover-light: hsl(212, 100%, 45%);
  --primary-outline-light: hsl(212, 100%, 46%);
  --primary-outline-hover-light: hsl(212, 100%, 40%);
  --surface-light: hsl(223, 100%, 96%);
  --on-surface-light: hsl(222, 11%, 40%);
  --on-surface-2-light: hsl(222, 11%, 36%);
  --surface-variant-light: hsl(221, 26%, 89%);
  --surface-variant-hover-light: hsl(221, 26%, 79%);
  --on-surface-variant-light: hsl(223, 14%, 35%);
  --surface-variant-outline-light: hsl(223, 18%, 85%);
  --surface-variant-outline-hover-light: hsl(223, 18%, 75%);
  --error-light: hsl(0, 54%, 49%);
  --error-alpha-10-light: hsla(0, 54%, 49%, 0.1);
  --outline-light: hsl(223, 10%, 83%);
  --surface-1-light: hsl(223, 100%, 96%);
  --surface-1-hover-light: hsl(223, 100%, 90%);
  --surface-1-outline-light: hsl(223, 49%, 92%);
  --surface-1-outline-hover-light: hsl(223, 49%, 82%);
  --alpha-80-light: hsla(0, 0%, 0%, 0.8);
  --alpha-70-light: hsla(0, 0%, 0%, 0.7);
  --alpha-60-light: hsla(0, 0%, 0%, 0.6);
  --alpha-50-light: hsla(0, 0%, 0%, 0.5);
  --alpha-10-light: hsla(0, 0%, 0%, 0.1);
  --alpha-5-light: hsla(0, 0%, 0%, 0.05);
  --overlay-bg-light: hsla(220, 8%, 56%, 0.5);

  /* Typography */

  --font-primary: "Poppins", sans-serif;

  --base-font-size: 62.5%;
  --title-1: 4.8rem;
  --title-2: 2.4rem;
  --title-3: 1.8rem;
  --body: 1.6rem;
  --label-1: 1.4rem;
  --label-2: 1.2rem;

  --weight-regular: 400;
  --weight-medium: 500;
  --weight-semiBold: 600;
  --weight-bold: 700;

  /* Border Radius */

  --radius-8: 8px;
  --radius-12: 12px;
  --radius-24: 24px;
  --radius-pill: 500px;
  --radius-circle: 50%;

  /* Others */
  --header-height: 72px;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    --transition-timing-function: cubic-bezier(0.14, 0.97, 0.59, 1);
    --transition-short: 150ms var(--transition-timing-function);
    --transition-medium: 250ms var(--transition-timing-function);
    --transition-long: 500ms var(--transition-timing-function);
  }

  html {
    scroll-behavior: smooth;
  }
}

[data-theme="dark"] {
  color-scheme: dark;

  --background: var(--background-dark);
  --on-background: var(--on-background-dark);
  --primary: var(--primary-dark);
  --primary-hover: var(--primary-hover-dark);
  --primary-outline: var(--primary-outline-dark);
  --primary-outline-hover: var(--primary-outline-hover-dark);
  --surface: var(--surface-dark);
  --on-surface: var(--on-surface-dark);
  --on-surface-2: var(--on-surface-2-dark);
  --surface-variant: var(--surface-variant-dark);
  --surface-variant-hover: var(--surface-variant-hover-dark);
  --on-surface-variant: var(--on-surface-variant-dark);
  --surface-variant-outline: var(--surface-variant-outline-dark);
  --surface-variant-outline-hover: var(--surface-variant-outline-hover-dark);
  --error: var(--error-dark);
  --error-alpha-10: var(--error-alpha-10-dark);
  --outline: var(--outline-dark);
  --surface-1: var(--surface-1-dark);
  --surface-1-hover: var(--surface-1-hover-dark);
  --surface-1-outline: var(--surface-1-outline-dark);
  --surface-1-outline-hover: var(--surface-1-outline-hover-dark);
  --alpha-80: var(--alpha-80-dark);
  --alpha-70: var(--alpha-70-dark);
  --alpha-60: var(--alpha-60-dark);
  --alpha-50: var(--alpha-50-dark);
  --alpha-10: var(--alpha-10-dark);
  --alpha-5: var(--alpha-5-dark);
  --overlay-bg: var(--overlay-bg-dark);
}

[data-theme="light"] {
  color-scheme: light;
  --background: var(--background-light);
  --on-background: var(--on-background-light);
  --primary: var(--primary-light);
  --primary-hover: var(--primary-hover-light);
  --primary-outline: var(--primary-outline-light);
  --primary-outline-hover: var(--primary-outline-hover-light);
  --surface: var(--surface-light);
  --on-surface: var(--on-surface-light);
  --on-surface-2: var(--on-surface-2-light);
  --surface-variant: var(--surface-variant-light);
  --surface-variant-hover: var(--surface-variant-hover-light);
  --on-surface-variant: var(--on-surface-variant-light);
  --surface-variant-outline: var(--surface-variant-outline-light);
  --surface-variant-outline-hover: var(--surface-variant-outline-hover-light);
  --error: var(--error-light);
  --error-alpha-10: var(--error-alpha-10-light);
  --outline: var(--outline-light);
  --surface-1: var(--surface-1-light);
  --surface-1-hover: var(--surface-1-hover-light);
  --surface-1-outline: var(--surface-1-outline-light);
  --surface-1-outline-hover: var(--surface-1-outline-hover-light);
  --alpha-80: var(--alpha-80-light);
  --alpha-70: var(--alpha-70-light);
  --alpha-60: var(--alpha-60-light);
  --alpha-50: var(--alpha-50-light);
  --alpha-10: var(--alpha-10-light);
  --alpha-5: var(--alpha-5-light);
  --overlay-bg: var(--overlay-bg-light);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a,
img,
svg,
span,
input,
button {
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  height: auto;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}
input {
  width: 100%;
}
button {
  cursor: pointer;
}

html {
  font-size: var(--base-font-size);
  --webkit-top-highlight-color: transparent; /* for mobile browser */
}

body {
  margin: 0;
  background: var(--background);
  color: var(--on-background);
  font-family: var(--font-primary);
  font-size: var(--label-1);
  font-weight: var(--weight-regular);
  line-height: 20px;
  letter-spacing: 0.2px;
  min-height: 100vh;
  min-height: 100dvh; /* for mobile browser */
  display: flex;
  flex-direction: column;
  padding-block: var(--header-height) 16px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--surface-variant);
  border-radius: var(--radius-pill);
}

/*-----------------------------------*\
  #UTILITY
\*-----------------------------------*/
.text-primary {
  color: var(--primary);
}

.text-error {
  color: var(--error);
}
/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/
.container {
  padding-inline: 16px;
  max-width: 1280px;
  width: 100%;
  margin-inline: auto;
}

.material-symbols-rounded {
  width: 1em;
  height: 1em;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  background-color: var(--alpha-50);
  overflow: hidden;
}

.avatar-circle {
  border-radius: var(--radius-circle);
}

.avatar-rounded {
  border-radius: var(--radius-24);
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-1 {
  font-size: var(--title-1);
  font-weight: var(--weight-medium);
  line-height: 72px;
}

.title-2 {
  font-size: var(--title-2);
  font-weight: var(--weight-semiBold);
  line-height: 28px;
}

.title-3 {
  font-size: var(--title-3);
  font-weight: var(--weight-regular);
  line-height: 24px;
}

.body {
  font-size: var(--body);
}

.label-1 {
  font-size: var(--label-1);
}

.label-2 {
  font-size: var(--label-2);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Button */

.btn {
  padding-inline: 24px;
  height: 40px;
  font-size: var(--label-1);
  font-weight: var(--weight-medium);
  border-radius: var(--radius-8);
  border: 1px solid transparent;
  max-width: max-content;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  line-height: 40px;
  transition: var(--transition-short);
}

.btn-primary {
  background-color: var(--primary);
  color: var(--white);
  border-color: var(--primary-outline);
}

.btn-primary:where(:hover, :focus-visible):not(:active) {
  background-color: var(--primary-hover);
  border-color: var(--primary-outline-hover);
}

.btn-secondary {
  background-color: var(--surface-1);
  color: var(--primary);
  border-color: var(--surface-1-outline);
}

.btn-secondary:where(:hover, :focus-visible):not(:active) {
  background-color: var(--surface-1-hover);
  border-color: var(--surface-1-outline-hover);
}

@media (min-width: 768px) {
  :root {
    --header-height: 80px;
  }

  body {
    padding-block: calc(var(--header-height) + 16px) 32px;
  }

  .container {
    padding-inline: 36px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-inline: 32px;
  }
}
