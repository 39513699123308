.avatar-skeleton {
  width: 160px;
  height: 160px;
  border-radius: var(--radius-circle);
  background-color: var(--alpha-10);
}

.title-skeleton,
.text-skeleton {
  border-radius: var(--radius-pill);
}

.title-skeleton {
  max-width: 180px;
  width: 100%;
  height: 24px;
  background-color: var(--alpha-10);
}

.text-skeleton {
  background-color: var(--alpha-10);
  height: 16px;
}

.profile-skeleton .title-skeleton {
  margin-block: 24px;
}
.profile-skeleton .text-skeleton {
  margin-block-start: 12px;
}
.profile-skeleton .text-1 {
  width: 55%;
}

.profile-skeleton .text-3 {
  width: 70%;
}

.skeleton {
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    transparent,
    var(--alpha-5),
    transparent
  );
  animation: skeleton-loading 1.5s linear infinite;
}

@keyframes skeleton-loading {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}

.repo-skeleton,
.follower-skeleton {
  background-color: var(--alpha-5);
}

.repo-skeleton .text-1 {
  margin-block: 12px;
}

.repo-skeleton .text-2 {
  width: 70%;
}

.repo-skeleton .card-footer {
  display: flex;
  gap: var(--spacing);
  border-block-start: 1px solid var(--alpha-5);
}

.repo-skeleton .card-footer .text-skeleton {
  max-width: 56px;
  flex-grow: 1;
}

.follower-skeleton {
  display: flex;
  align-items: center;
  padding: var(--spacing);
  gap: var(--spacing);
}

.follower-skeleton .avatar-skeleton {
  width: 56px;
  height: 56px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .avatar-skeleton {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: 992px) {
  .avatar-skeleton {
    width: 280px;
    height: 280px;
  }
}
