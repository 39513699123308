.skip-to-content {
  position: fixed;
  top: 4px;
  left: 4px;
  height: 56px;
  display: grid;
  place-items: center;
  background-color: var(--on-background);
  padding-inline: 24px;
  color: var(--background);
  font-size: var(--label-1);
  font-weight: var(--weight-medium);
  border-radius: var(--radius-8);
  outline-offset: 2px;
  opacity: 0;
  pointer-events: none;
  z-index: 5;
}

.skip-to-content:focus-visible {
  opacity: 1;
  pointer-events: all;
}

.header {
  background-color: var(--background);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  transition: background-color var(--transition-short);
  z-index: 4;
}

.header.active {
  background-color: var(--surface);
}

.header .container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo {
  color: var(--on-background);
  font-size: 26px;
  font-weight: var(--weight-bold);
  line-height: 26px;
}

.logo .text-primary {
  display: inline;
}

.header-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}

.header:not(.search-active) .header-search {
  margin-inline-start: auto;
}
.header:not(.search-active) .search-box,
.header.search-active :where(.logo, .theme-btn),
.search-toggler[aria-expanded="true"] .search-icon,
.search-toggler[aria-expanded="false"] .close-icon,
[data-theme="dark"] .theme-btn .moon-icon,
[data-theme="light"] .theme-btn .sun-icon {
  display: none;
}

.search-box .leading-icon,
.search-btn .label-1 {
  display: none;
}

.search-box {
  position: relative;
  border-radius: var(--radius-pill);
  overflow: hidden;
  flex-grow: 1;
}

.search-field {
  height: 48px;
  line-height: 48px;
  background-color: var(--surface-variant);
  padding-inline: 16px 64px;
  border-radius: inherit;
  outline: none;
}

.search-field:focus {
  box-shadow: inset 0 0 0 2px var(--primary);
}

.search-field::placeholder {
  color: var(--alpha-60);
}

.search-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  background-color: var(--primary);
  color: var(--white);
  font-weight: var(--weight-medium);
  display: grid;
  place-items: center;
  border-radius: var(--radius-pill);
  transition: var(--transition-short);
}

.search-btn:where(:hover, :focus-visible):not(:active) {
  background-color: var(--primary-hover);
}

@media (min-width: 768px) {
  .search-btn .material-symbols-rounded,
  .icon-btn.search-toggler {
    display: none;
  }

  .search-box .leading-icon,
  .search-btn .label-1,
  .header.header:not(.search-active) .search-box {
    display: block;
  }

  .search-box .leading-icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  .search-field {
    padding-inline: 56px 88px;
  }

  .search-btn {
    width: max-content;
    padding-inline: 16px;
  }

  .header-search {
    max-width: 360px;
  }
}
